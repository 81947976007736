import React from 'react';
import { LabeledElement, Input } from 'components/form-elements';

export const LabeledInput = ({
  id,
  label,
  ...props
}) => (
  <LabeledElement
    id={id}
    label={label}
  >
    <Input
      variant="input.labeled"
      id={id}
      {...props}
    />
  </LabeledElement>
);
