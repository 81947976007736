import { forms } from 'global/theming/variants/forms';
import { get } from 'lodash-es';

const labeledStyles = forms.input.labeled;

export const styles = themeUIColors => ({
  control: (provided, state) => ({
    ...provided,
    width: labeledStyles.width,
    height: labeledStyles.height,
    minHeight: labeledStyles.height,
    borderRadius: labeledStyles.borderRadius,
    borderWidth: labeledStyles.borderWidth,
    borderStyle: labeledStyles.borderStyle,
    borderColor: get(themeUIColors, labeledStyles.borderColor),
    boxShadow: labeledStyles.boxShadow || 'none',
    backgroundColor: get(themeUIColors, labeledStyles.backgroundColor),
    opacity: state.isDisabled ? 0.7 : 1,
  }),
  singleValue: (provided) => ({
    ...provided,
    top: '43%',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    paddingLeft: '10px',
    fontSize: labeledStyles.fontSize,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: labeledStyles.fontSize,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginRight: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
