import React from 'react';
import ReactSelect, { createFilter } from 'react-select';

export const Select = ({
  id,
  name,
  styles,
  components,
  isDisabled,
  isLoading,
  options = [],
  value,
  placeholder = '',
  onChange, 
}) => (
  <ReactSelect
    isClearable
    components={components}
    styles={styles}
    id={id}
    filterOption={createFilter({ ignoreAccents: false })}
    name={name}
    value={value}
    isDisabled={isDisabled}
    isLoading={isLoading}
    options={options}
    placeholder={placeholder}
    onChange={onChange}
  />
);

