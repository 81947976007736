import React from 'react';
import * as Icons from 'components/icons';
import { SideBarMenu } from './components/SideBarMenu';

export const SideBar = () => (
  <SideBarMenu>
    <SideBarMenu.NavMenu
      icon={<Icons.Overview />}
      text="Overview"
      to="/"
      isDisabled
    />

    <SideBarMenu.NavMenu
      icon={<Icons.Tasks />}
      text="Tasks"
      to="/"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Magnify size={17} />}
      text="Search"
      items={[
        { name: 'Add', to: '/add', disabled: true },
        { name: 'Import buildings', to: '/import-buildings', disabled: true },
        { name: 'Import subscriptions', to: '/import-subscriptions', disabled: true },
        { name: 'Settings', to: '/settings', disabled: true },
      ]}
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Building />}
      text="Building"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Product />}
      text="Product"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Operations />}
      text="Operations"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Fulfillment />}
      text="Fulfillment"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Assurance />}
      text="Assurance"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Billing />}
      text="Billing"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Enterprise />}
      text="Enterprise"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Tools />}
      text="Tools"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={<Icons.Helpcenter />}
      text="Helpcenter"
      isDisabled
    />

    <SideBarMenu.DropdownMenu
      icon={(
        <Icons.Settings size={18} />
      )}
      text="Settings"
      isDisabled
    />

  </SideBarMenu>
);
