import { QueryCache } from 'react-query';
import * as ReactQuery from 'react-query';
import React from 'react';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      staleTime: Infinity, // Change to milliseconds in case you want to refetch data in the background
      cacheTime: Infinity, // Change to milliseconds in case you want to garbage collect unused data
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const ServiceProvider = ({ children }) => (
  <ReactQuery.ReactQueryCacheProvider queryCache={queryCache}>
    {children}
  </ReactQuery.ReactQueryCacheProvider>
);
