import React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as Pages from 'pages';
import { Routing } from 'global/routing';
import { AuthGuard } from 'components/app';
import { Header } from 'components/Header';
import { Flex } from 'components/layout';
import { SideBar } from 'components/SideBar';

export const Routes = () => (
  <ReactRouter.Switch>
    <ReactRouter.Route
      exact
      path={Routing.LOGIN_PAGE}
      component={Pages.Login}
    />
    <AuthGuard>
      <Header />

      <Flex
        justifyContent="center"
        gap="8px"
        sx={{
          my: '8px',
        }}
      >
        <SideBar />
        <ReactRouter.Route
          exact
          path={Routing.HOME_PAGE}
          component={Pages.Home}
        />
      </Flex>
    </AuthGuard>
  </ReactRouter.Switch>
);
