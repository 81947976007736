import { authorize, putRequest } from 'utils/http';
import { Environment } from 'global/environment';
import { mapDataForBackend } from './mapData';

export async function updateCustomerAPI(customer) {
  return putRequest(
    `${Environment.BACKEND_API}/update/user/${customer.id}`,
    mapDataForBackend(customer),
    await authorize(),
  );
}
