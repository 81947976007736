import React from 'react';
import { useThemeUI } from 'theme-ui';
import { LabeledElement, Select } from 'components/form-elements';
import { DropdownIndicator } from './components';
import { styles } from './styles';

export const LabeledSelect = ({
  id,
  label,
  isDisabled,
  ...props
}) => {
  const themeUI = useThemeUI();

  const selectStyles = React.useMemo(
    () => styles(themeUI.theme.colors),
    [themeUI.theme.colors],
  );

  return (
    <LabeledElement
      id={id}
      label={label}
      isDisabled={isDisabled}
    >
      <Select
        id={id}
        styles={selectStyles}
        components={{ DropdownIndicator }}
        isDisabled={isDisabled}
        {...props}
      />
    </LabeledElement>
  );
};
