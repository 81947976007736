import { pickBy, identity } from 'lodash-es';
import { startOfDay, endOfDay } from 'date-fns';

export const mapDataForBackend = searchFilters => pickBy({
  textSearch: searchFilters.freeTextSearch,
  registrationno: searchFilters.organizationNumber,
  address: searchFilters.address,
  phone: searchFilters.phoneNumber,
  building: searchFilters.buildingId ? searchFilters.buildingId.value : null,
  status: searchFilters.status ? searchFilters.status.value : null,
  orderFromDate: searchFilters.date.selectedDateFrom && startOfDay(searchFilters.date.selectedDateFrom),
  orderToDate: searchFilters.date.selectedDateTo && endOfDay(searchFilters.date.selectedDateTo),
}, identity);

export const mapDataForClient = (customer, index) => ({
  name: customer.name,
  customerNumber: customer.customerno,
  identifier: customer.registrationno,
  orderValue: customer.orders.reduce((prev, curr) => prev + parseInt(curr.cost, 10), 0),
  area: index % 2 ? 'Xanandu' : 'Utopia',
  firstName: customer.firstname,
  lastName: customer.lastname,
  mobile: customer.mobilephone,
  email: customer.email,
  id: customer._id,
  object: customer.object,
  address: customer.address,
});
