import React, { useEffect } from 'react';
import { Loader } from 'components/loaders';
import { Routing } from 'global/routing';
import { ShouldRender } from 'components/ShouldRender';
import { useRedirect } from 'utils/redirect';
import { getUser } from 'utils/auth';
import { useUser } from 'store/user';
import { mapDataForClient } from './mapData';

export const AuthGuard = ({ children }) => {
  const { user, setUser } = useUser();
  const [isFetchingUser, setIsFetchingUser] = React.useState(false);

  const { onRedirect } = useRedirect(Routing.LOGIN_PAGE);
  
  useEffect(() => {
    setIsFetchingUser(true);
    const updateUser = async () => {
      try {
        const user = await getUser();
        setIsFetchingUser(false);
        setUser(user ? mapDataForClient(user) : null);
      } catch (ex) {
        setIsFetchingUser(false);
        onRedirect();
      }

    };
    updateUser();
    // eslint-disable-next-line
  }, [setUser]);

  return (
    <>
      {user ? children : (
        <ShouldRender when={isFetchingUser}>
          <Loader />
        </ShouldRender>
      )}
    </>
  );
};
