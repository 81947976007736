import React from 'react';
import { Text } from 'components/typography';
import { NavLink } from 'components/links';
import { MenuContainer } from '../MenuContainer';

export const NavMenu = ({ isDisabled, icon, text, to }) => (
  <NavLink
    to={to}
  >
    <MenuContainer
      isDisabled={isDisabled}
      icon={icon}
    >
      <Text
        isFaded={isDisabled}
        variant="sideBar.navMenu"
      >
        {text}
      </Text>
    </MenuContainer>
  </NavLink>
);
