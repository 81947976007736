import React from 'react';

export const BoxArrow = ({ size = 29, className }) => (
  <svg
    className={className}
    style={{ width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
  >
    <g transform="translate(-622.5 -505)">
      <g fill="#ddd" transform="translate(622.5 505)">
        <path stroke="none"
              d="M 24 28.5 L 5 28.5 C 2.51869010925293 28.5 0.5 26.48130989074707 0.5 24 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 24 0.5 C 26.48130989074707 0.5 28.5 2.51869010925293 28.5 5 L 28.5 24 C 28.5 26.48130989074707 26.48130989074707 28.5 24 28.5 Z"/>
        <path stroke="none"
              fill="#979797"
              d="M 5 1 C 2.794389724731445 1 1 2.794389724731445 1 5 L 1 24 C 1 26.20561027526855 2.794389724731445 28 5 28 L 24 28 C 26.20561027526855 28 28 26.20561027526855 28 24 L 28 5 C 28 2.794389724731445 26.20561027526855 1 24 1 L 5 1 M 5 0 L 24 0 C 26.76141929626465 0 29 2.238580703735352 29 5 L 29 24 C 29 26.76141929626465 26.76141929626465 29 24 29 L 5 29 C 2.238580703735352 29 0 26.76141929626465 0 24 L 0 5 C 0 2.238580703735352 2.238580703735352 0 5 0 Z"/>
      </g>
      <path fill="#3e3e3e" className="b" d="M5.5,0,11,10H0Z" transform="translate(643 526) rotate(180)"/>
    </g>
  </svg>
);
